import React from 'react'
import styled from 'styled-components'

const ContainerCalendar = styled(({ tag = 'div', ...props }) => (
  <div as={tag} {...props}/>
))`
  margin: 0 auto;
  position: relative;
  width: 83.3%;
  min-height: 60vh;
  max-width: 75rem;
  padding: 1.5rem 0 3.75rem;

  ${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
    padding: 2rem 0 3.75rem;
  }

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    width: calc(100% - 36px);
  }
 
  ${({ theme }) => theme.mediaQueries.tabletDS} {
    width: calc(100% - 48px);
}
`

export default ContainerCalendar
