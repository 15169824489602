import { PricingV2Response, useQueryPricingV2 } from '@/hooks/useQuery';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

type WeeksToShow = 'all' | 'upcomming';

type PricingDataContextType = {
  propertyID: string | null;
  setPropertyID: (propertyID: string) => void;
  year: number;
  setYear: (year: number) => void;
  duration: number;
  setDuration: (duration: number) => void;
  weeksToShow: WeeksToShow;
  setWeeksToShow: (weeksToShow: WeeksToShow) => void;
  pricingData?: PricingV2Response;
};

export const PricingDataContext = createContext<PricingDataContextType>(null as any);

interface PricingDataProviderProps {
  children: ReactNode;
}

export function PricingDataProvider({ children }: PricingDataProviderProps) {
  const [propertyID, setPropertyID] = useState<string | null>(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [duration, setDuration] = useState(7);
  const [weeksToShow, setWeeksToShow] = useState<WeeksToShow>('all');

  const { data: pricingData } = useQueryPricingV2({ propertyID, year, duration });

  const value = useMemo<PricingDataContextType>(
    () => ({
      propertyID,
      setPropertyID,
      year,
      setYear,
      duration,
      setDuration,
      weeksToShow,
      setWeeksToShow,
      pricingData,
    }),
    [
      propertyID,
      setPropertyID,
      year,
      setYear,
      duration,
      setDuration,
      weeksToShow,
      setWeeksToShow,
      pricingData,
    ]
  );

  return (
    <PricingDataContext.Provider value={value}>
      {children}
    </PricingDataContext.Provider>
  );
}

export function usePricingDataContext() {
  const context = useContext(PricingDataContext);

  if (!context) {
    throw new Error(
      'usePricingDataContext must be used within a PricingDataProvider'
    );
  }

  return context;
}
