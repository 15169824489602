import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import React, { useState, useEffect, useRef } from 'react'
import useWindowSize from '@/hooks/useWindowSize'
import { Year, CalendarWrapper, SwitcherContainer } from './BookingCalendar.styles'
import {
  BookedModal,
  CancelledBookingModal,
  NewBookingModalConsolidated as NewBookingModal,
  SuccessfulBookingModalConsolidated as SuccessfulBookingModal } from '@/components/__Modals'
import { ChevronLeft, ChevronRight } from '@awaze/design-system.components.icon'
import { 
  baseKeyBookings,
  baseKeyBookingsInfinite,
  useQueryBookingSearch,
  useQueryUserPropertyEntitlements 
} from '@/hooks/useQuery'
import { BookingCalendarYear } from './BookingCalendarYear'
import { BookingCalendarMonth } from './BookingCalendarMonth'
import { useQueryClient } from '@tanstack/react-query'
import Switcher from '@/components/__UI/Switch'
import { useTranslation } from 'react-i18next'

const getPropertyIdOrNull = p => p ? p.propertyId : null

const BookingCalendar = ({ property, unitId, year, setYear, showAvailability, setShowAvailability }) => {
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const queryClient = useQueryClient()
  const refetchBookings = () => {
    queryClient.invalidateQueries({ queryKey: baseKeyBookings })
    queryClient.invalidateQueries({ queryKey: baseKeyBookingsInfinite })
  }

  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedBookingID, setSelectedBookingID] = useState(null)
  const [successfulBooking, setSuccessfulBooking] = useState(null)
  const [cancelledBookingID, setCancelledBookingID] = useState(false)
  const [cancelledBookingData, setCancelledBookingData] = useState(null)
  const [bookings, setBookings] = useState([])
  const [closures, setClosures] = useState([])
  const [showWeekNumber, setshowWeekNumber] = useState(false)
  const [shouldShowToggle, setShouldShowToggle] = useState(false)

  const abortControllerRef = useRef(new AbortController())
  useEffect(() => {
    const abortController = abortControllerRef.current
    return () => {
      abortController.abort()

      setSelectedDay(null)
      setSelectedBookingID(null)
      setSuccessfulBooking(null)
      setCancelledBookingID(false)
    }
  }, [property, year])

  // TODO OUK-8798 - fetch last X days of previous year to get bookings that span into the current year
  const propertyId = getPropertyIdOrNull(property)

  const { data: authorisations } = useQueryUserPropertyEntitlements({ propertyId: getPropertyIdOrNull(property) })
  const { data: booking, isLoading: loadingBooking } = useQueryBookingSearch(selectedBookingID ? String(selectedBookingID) : null)

  useEffect(() => {
    if (booking) {
      setCancelledBookingData(booking)
    }
  }, [booking])

  const hasAuth = (auth) => authorisations?.some(({ name, authorised }) => name === auth && authorised) || false

  const showNewBookingModal = selectedDay && hasAuth('createBooking')
  const showCancelledBookingModal = cancelledBookingID

  const currentYear = dayjs().year()

  const handlePreviousYear = () => {
    setYear((prevYear) => {
      const newYear = prevYear - 1
      return newYear >= currentYear - 2 ? newYear : prevYear
    })
  }

  const handleNextYear = () => {
    setYear((prevYear) => {
      const newYear = prevYear + 1
      return newYear <= currentYear + 2 ? newYear : prevYear
    })
  }

  const mapContractExpiry = (startDateIso) => {
    const startDate = dayjs(startDateIso)

    const endDate = startDate.add(3, 'year')

    const startDateFormatted = startDate.format('MM/DD/YYYY 00:00:00')
    const endDateFormatted = endDate.format('MM/DD/YYYY 00:00:00')

    return {
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      startDateIso: startDate.toISOString(),
      endDateIso: endDate.toISOString()
    }
  }

  const contractExpiry = mapContractExpiry(property?.propertyUnits[0]?.endDateTime)

  return (
    <>
      {showNewBookingModal && (
        <NewBookingModal
          bookings={bookings}
          closures={closures}
          selectedDate={selectedDay}
          property={property}
          onSuccess={data => {
            refetchBookings()
            setSuccessfulBooking(data)
          }}
          onClose={() => setSelectedDay(null)}
          managedProperty={property && property.managedProperty}
          allowPassengers={property && property.allowLeadPassenger}
          unitId={unitId}
        />
      )}

      {showCancelledBookingModal && (
        <CancelledBookingModal
          data={{ bookingId: cancelledBookingID, ...cancelledBookingData, ...property }}
          onClose={() => setCancelledBookingID(null)}
          onConfirm={() => { }}
          onCancelled={() => refetchBookings()}
        />
      )}

      {selectedBookingID && (
        <BookedModal
          canCancel={hasAuth('cancelBooking')}
          onClose={() => setSelectedBookingID(null)}
          onCancel={id => setCancelledBookingID(id)}
          data={booking ? { bookingId: selectedBookingID, ...booking } : null}
          loading={loadingBooking}
        />
      )}

      {successfulBooking && (
        <SuccessfulBookingModal
          onClose={() => setSuccessfulBooking(null)}
          data={successfulBooking}
        />
      )}

      <CalendarWrapper yearCalendar={isDesktop}>
        {isDesktop && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button
            onClick={handlePreviousYear}
            aria-label="Previous Year"
            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            disabled={year <= currentYear - 1}
          >
            <ChevronLeft iconColour={year <= currentYear - 1 ? 'ColorBlackOpacity20' : 'ColorThemeLink'}/>
          </button>
          <Year>{year}</Year>
          <button
            onClick={handleNextYear}
            aria-label="Next Year"
            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            disabled={year >= currentYear + 2}
          >
            <ChevronRight iconColour={year >= currentYear + 2 ? 'ColorBlackOpacity20' : 'ColorThemeLink'}/>
          </button>
        </div>}
        {isDesktop && (
          <>
            <BookingCalendarYear
              year={year}
              yearCalendar={isDesktop}
              unitId={unitId}
              propertyId={propertyId}
              setSelectedDay={setSelectedDay}
              setSelectedBookingID={setSelectedBookingID}
              hasAuth={hasAuth}
              setBookings={setBookings}
              setClosures={setClosures}
              contractExpiry={contractExpiry}
              showWeekNumber={showWeekNumber}
              showAvailability={showAvailability}
              onToggleShowAvailability={setShouldShowToggle}
            />
            <SwitcherContainer>
              <Switcher checked={showWeekNumber} onChange={setshowWeekNumber} label={t('showWeekNumber')} />
              { shouldShowToggle && <Switcher checked={showAvailability} onChange={setShowAvailability} label={t('showAvailability')} /> }
            </SwitcherContainer>
          </>
        )}

{!isDesktop && (
        <BookingCalendarMonth
          year={year}
          yearCalendar={isDesktop}
          unitId={unitId}
          propertyId={propertyId}
          setSelectedDay={setSelectedDay}
          setSelectedBookingID={setSelectedBookingID}
          hasAuth={hasAuth}
          setBookings={setBookings}
          setClosures={setClosures}
          contractExpiry={contractExpiry}
          showWeekNumber
          showAvailability={showAvailability}
          setShowAvailability={setShowAvailability}
        />
    )}
      </CalendarWrapper>
    </>
  )
}

BookingCalendar.propTypes = {
  property: PropTypes.object,
  unitId: PropTypes.string,
  year: PropTypes.number,
  setYear: PropTypes.func.isRequired
}

export default BookingCalendar
