import { Heading, Text } from '@awaze/design-system.components.text';
import { useTranslation } from 'react-i18next';
import { TableControls, TableControlsRight } from './PricingListTableControls.styles';
import { PricingListTableTooltip } from './PricingListTableTooltip';
import { usePricingTableDataVisibility } from '../PricingListTable/columns/columnDefinitions';
import { usePricingDataContext } from '../context/PricingDataProvider';
  

export function PricingListTableControls() {
    const { t } = useTranslation();
    const { year, pricingData } = usePricingDataContext()
    const dataToShow = usePricingTableDataVisibility()

    return (
        <TableControls>
          {/* TODO (OUK-9905) Year picker*/}
          <Heading sizes={100}>{year}</Heading>
          <TableControlsRight>
            <Text sizes={200}>
              {t('currency')}: {pricingData?.currency}
            </Text>
            {/* TODO filters goes here */}
            <PricingListTableTooltip showIncome={dataToShow.income} showDynamicPrice={dataToShow.currentPrice} />
          </TableControlsRight>
        </TableControls>

    )
}